<template>
    <ion-page>
        <ion-header :translucent="true">
            <ion-toolbar>
                <ion-buttons slot="start">
                    <ion-button @click="closeModal()">
                        <ion-icon slot="start" :icon="arrowBackOutline" color="white"></ion-icon>
                    </ion-button>
                </ion-buttons>
                <ion-title color="white">Nuovo todo</ion-title>
            </ion-toolbar>
        </ion-header>

        <ion-content :fullscreen="true">
            <div class="page_content">
                <form @submit.prevent="creaSpesa()">
                    <ion-list class="fields">
                        <!-- titolo -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Titolo
                                    <ion-text color="danger"><strong>*</strong></ion-text>
                                </div>
                                <div class="value">
                                    <ion-input type="text" v-model="todo.titolo" placeholder="Titolo della todo"> </ion-input>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data scadenza -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Data scadenza
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2023"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="todo.data_scadenza"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Data inizio -->
                        <ion-item lines="none" class="ion-no-padding">
                            <div class="field">
                                <div class="field_title">
                                    Ricordamelo il
                                </div>
                                <div class="value">
                                    <ion-datetime
                                        name="date_start"
                                        display-format="DD/MM/YYYY"
                                        picker-format="DD/MM/YYYY"
                                        min="2023"
                                        max="2099"
                                        slot="end"
                                        doneText="OK"
                                        cancelText="Annulla"
                                        v-model="todo.data_ricorda"
                                    ></ion-datetime>
                                </div>
                            </div>
                        </ion-item>

                        <!-- prezzo -->
                        <ion-item v-if="categories.length != 0" lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">
                                    Categoria
                                </div>
                                <div class="value">
                                    <ion-select
                                        name="categoria"
                                        interface="action-sheet"
                                        cancel-text="Annulla"
                                        ok-text="Conferma"
                                        v-model="selectedCategory"
                                        placeholder="Seleziona categoria"
                                    >
                                        <ion-select-option
                                            v-for="category in categories"
                                            :key="category.fi_todo_list_categories_id"
                                            :value="category.fi_todo_list_categories_id"
                                            :style="{
                                                color: `${category.fi_todo_lists_color}!important`,
                                            }"
                                        >
                                            {{ category.fi_todo_list_categories_name }}
                                        </ion-select-option>
                                    </ion-select>
                                </div>
                            </div>
                        </ion-item>

                        <!-- Note -->
                        <ion-item lines="none" class="field ion-no-padding">
                            <div class="field">
                                <div class="field_title" position="floating">Note</div>
                                <div class="value">
                                    <ion-textarea type="text" rows="3" v-model="todo.note" placeholder="Testo della todo"> </ion-textarea>
                                </div>
                            </div>
                        </ion-item>

                        <div class="actions">
                            <button type="submit" class="btn_salva" :disabled="savingTodo">
                                Crea todo
                            </button>
                        </div>
                    </ion-list>
                </form>
            </div>
        </ion-content>
    </ion-page>
</template>

<script>
import {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    modalController,
    IonList,
    IonItem,
    IonDatetime,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonText,
    IonInput,
    IonIcon,
    actionSheetController,
} from "@ionic/vue";
import { arrowBackOutline, trash } from "ionicons/icons";

import { defineComponent, ref, toRefs, reactive } from "vue";

import { LOCALSTORAGE_KEY } from "@/custom_config";

import moment from "moment";

import { openToast } from "@/services/toast";
import apiTodo from "@/services/todo";

export default defineComponent({
    name: "ModalNuovaTodo",
    props: {
        listaId: {
            type: String,
            required: true,
        },
    },
    components: {
        IonPage,
        IonContent,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonList,
        IonItem,
        IonDatetime,
        IonSelect,
        IonSelectOption,
        IonTextarea,
        IonText,
        IonInput,
        IonIcon,
    },
    setup(props, context) {
        //console.log(props);
        const userID = JSON.parse(localStorage.getItem(`${LOCALSTORAGE_KEY}`)).dipendenti_user_id; //set userID in user field

        const todo = reactive({
            titolo: null,
            data_scadenza: null,
            data_ricorda: null,
            note: null,
            category: null,
        });

        const successResponse = ref(false);

        const { listaId } = toRefs(props);
        //console.log(listaId.value);

        /**
         * ! Close modal after form data submit
         */
        async function closeModalOnSubmit(successResponse, res) {
            if (successResponse.value) {
                await modalController.dismiss(res);
            } else {
                await modalController.dismiss();
            }
        }

        /**
         * ! Handle close modal by clicking on Chiudi button
         */
        const closeModal = () => {
            modalController.dismiss();
        };

        /**
         * ! Load categories for the current list
         */
        const categories = ref([]);
        const selectedCategory = ref(null);
        async function loadCatgories() {
            try {
                const res = await apiTodo.getCategories(listaId.value);

                if (res.status === 200 && res.data.status === 0) {
                    categories.value = res.data.data;
                } else {
                    openToast("Si è verificato un errore durante la richiesta della categorie", "toast_danger");
                }
            } catch (error) {
                console.log(error);
                openToast("Si è verificato un errore durante la richiesta della categorie", "toast_danger");
            }
        }
        loadCatgories();

        /**
         * ! Create new todo
         */
        const savingTodo = ref(false);
        async function creaSpesa() {
            if (!todo.titolo) {
                openToast("Il titolo è obbligatorio", "toast_danger");
                return;
            }

            savingTodo.value = true;

            let dataScadenza = null;
            if (todo.data_scadenza) {
                dataScadenza = moment(todo.data_inizio).format("YYYY-MM-DD");
            }

            let dataRicorda = null;
            if (todo.data_ricorda) {
                dataRicorda = moment(todo.data_inizio).format("YYYY-MM-DD");
            }

            const data = new FormData();
            data.append("fi_todo_list", listaId.value);
            data.append("fi_todo_created_by", userID);
            data.append("fi_todo_done", 0);
            data.append("fi_todo_starred", 0);
            data.append("fi_todo_text", todo.titolo);

            if (dataScadenza) {
                data.append("fi_todo_deadline", dataScadenza);
            }
            if (dataRicorda) {
                data.append("fi_todo_reminder", dataRicorda);
            }
            if (selectedCategory.value) {
                data.append("fi_todo_category", selectedCategory.value);
            }
            if (todo.note) {
                data.append("fi_todo_note", todo.note);
            }
            //Display the values
            /* for (const value of data.entries()) {
                console.log(value[0] + ", " + value[1]);
            }
            return; */

            try {
                const res = await apiTodo.createTodo(data);
                //console.log(res);
                if (res.status === 200 && res.data.status === 0) {
                    const todoCreated = res.data.data[0];
                    successResponse.value = true;
                    closeModalOnSubmit(successResponse, todoCreated);
                } else {
                    openToast("Si è verificato un errore durante il salvataggio", "toast_danger");
                }
            } catch (error) {
                console.log(error);
                openToast("Si è verificato un errore durante il salvataggio", "toast_danger");
            } finally {
                savingTodo.value = false;
            }
        }

        return {
            todo,
            selectedCategory,
            categories,
            creaSpesa,
            closeModal,
            arrowBackOutline,
            trash,
            savingTodo,
        };
    },
});
</script>

<style scoped>
ion-toolbar {
    --background: #086fa3;
    --color: #ffffff;
}

/* New rules */
.page_content {
    width: 100%;
    position: relative;
    min-height: 100%;
    padding: 16px;
}

.fields {
    width: 100%;
    border-radius: 4px;
    /*padding: 10px;*/
    padding: 16px;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
}
.field {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    margin-bottom: 8px;
}

.field_title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
    color: #6b7280;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --highlight-height: 0;
    --ripple-color: transparent;
    --background-activated: transparent;
    --background-activated-opacity: 0;
    margin-bottom: 4px;
}
ion-datetime,
ion-select {
    max-width: 100%;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    --padding-start: 8px;
}

ion-input,
ion-textarea {
    --placeholder-color: #6b7280;
    --padding-start: 8px !important;
    --padding-end: 8px !important;
    border: 1px solid #d1d5db;
    border-radius: 4px;
    margin-top: 0;
}

/* .btn_richiesta {
  --background: #16a34a;
  --background-activated: #15803d;
  --background-hover: #15803d;
  --background-focused: #15803d;
  --box-shadow: none;
  --border-radius: 4px;
  --padding-top: 10px;
  --padding-bottom: 10px;
  --padding-start: 10px;
  --padding-end: 10px;
  --ripple-color: none;
  --color: #ffffff;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
} */
.btn_richiesta {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    margin-bottom: 8px;
    text-align: center;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    background-color: rgb(22 163 74);
    color: #ffffff;
}

.btn_salva {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-weight: 600;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
    background-color: #0a90d4;
    color: #ffffff;
    box-shadow: 0px 2px 3px 0px rgba(58, 58, 58, 0.1);
    transition: all 0.15s ease-in;
}
.btn_salva:disabled {
    opacity: 0.55;
}

ion-item {
    --padding-start: 0;
    --padding-end: 0;
    --padding-left: 0;
    --padding-right: 0;
    --inner-padding-end: 0;
}

/* Image rules */
.img_photo {
    position: relative;
    max-height: 300px;
}
.img_action {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #dc2626;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
}
ion-button {
    --color: #ffffff;
}
</style>
